
import ApiService from "@/common/api.service"

import { GetterTree, ActionTree, MutationTree, Module } from 'vuex'
import API from "@/common/API"

/* eslint-disable @typescript-eslint/no-explicit-any */

// action types
export const LIST_ORDERS_SEARCH = "orders"
export const ADD_ORDER = "addOrder"
export const DETAIL_ORDER = "detailOrder"
export const UPDATE_ORDER = "updateOrder"
export const DELETE_ORDER = "deleteOrder"
export const UPDATE_ORDER_STATUS = "updateOrderStatus"

// mutation types

export const SET_LIST_ORDERS = "setListOrders"
export const SET_DETAIL_ORDER = "setDetailOrders"
export const SET_ERROR = "setError"


export const state = () => ({
    errors: null,
    orders: [],
    order: {},
})

type RootState = ReturnType<typeof state>
const getters: GetterTree<RootState, RootState> = {
    orders: state => state.orders,
    order: state => state.order,
}

const mutations: MutationTree<RootState> = {
    [SET_ERROR](state, error) {
        state.errors = error
    },
    [SET_LIST_ORDERS](state, orders) {
        state.orders = orders
    },
    [SET_DETAIL_ORDER](state, order) {
        state.order = order
    },
}

const actions: ActionTree<RootState, RootState> = {
    [DETAIL_ORDER](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.get(API.ORDERS, credentials)
                .then((data: any) => {
                    if (data.status) {
                        context.commit(SET_DETAIL_ORDER, data.data.data)
                    } else {
                        context.commit(SET_ERROR, data.message)
                    }
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    [LIST_ORDERS_SEARCH](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(API.ORDERS, credentials)
                .then((data: any) => {
                    if (data.status) {
                        context.commit(SET_LIST_ORDERS, data.data.data)
                    } else {
                        context.commit(SET_ERROR, data.message)
                    }
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    [ADD_ORDER](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post(API.ORDERS, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data)
                })
        })
    },
    [UPDATE_ORDER](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.put(`${API.ORDERS}/${credentials.id}`, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data)
                })
        })
    },
    [DELETE_ORDER](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`${API.ORDERS}`, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data)
                })
        })
    },
    [UPDATE_ORDER_STATUS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.put(API.UPDATE_ORDER_STATUS(payload.id), { status: payload.status })
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data)
                })
        })
    },
}
const orderModule: Module<any, any> = {
    state,
    mutations,
    actions,
    getters,
}

export default orderModule