
import ApiService from "@/common/api.service"

import { GetterTree, ActionTree, MutationTree, Module } from 'vuex'
import API from "@/common/API"

/* eslint-disable @typescript-eslint/no-explicit-any */

// action types
export const LIST_GROUPS_SEARCH = "groups"
export const ADD_GROUP = "addGroup"
export const DETAIL_GROUP = "detailGroup"
export const UPDATE_GROUP = "updateGroup"
export const DELETE_GROUP = "deleteGroup"
export const GROUP_ACCEPT_PUBLISH = "acceptPublishGroup"

// mutation types

export const SET_LIST_GROUPS = "setListGroups"
export const SET_DETAIL_GROUP = "setDetailGroups"
export const SET_ERROR = "setError"


export const state = () => ({
    errors: null,
    groups: [],
    group: {},
})

type RootState = ReturnType<typeof state>
const getters: GetterTree<RootState, RootState> = {
    groups: state => state.groups,
    group: state => state.group,
}

const mutations: MutationTree<RootState> = {
    [SET_ERROR](state, error) {
        state.errors = error
    },
    [SET_LIST_GROUPS](state, groups) {
        state.groups = groups
    },
    [SET_DETAIL_GROUP](state, group) {
        state.group = group
    },
}

const actions: ActionTree<RootState, RootState> = {
    [DETAIL_GROUP](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.get(API.GROUPS, credentials)
                .then((data: any) => {
                    if (data.status) {
                        context.commit(SET_DETAIL_GROUP, data.data.data)
                    } else {
                        context.commit(SET_ERROR, data.message)
                    }
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    [LIST_GROUPS_SEARCH](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(API.GROUPS, credentials)
                .then((data: any) => {
                    if (data.status) {
                        context.commit(SET_LIST_GROUPS, data.data.data)
                    } else {
                        context.commit(SET_ERROR, data.message)
                    }
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    [ADD_GROUP](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post(API.GROUPS, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data)
                })
        })
    },
    [UPDATE_GROUP](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.put(`${API.GROUPS}/${credentials.id}`, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data)
                })
        })
    },
    [DELETE_GROUP](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`${API.GROUPS}`, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data)
                })
        })
    },
    [GROUP_ACCEPT_PUBLISH](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${API.GROUP_ACCEPT_PUBLISH}/${payload}`, {})
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data)
                })
        })
    },
}
const groupModule: Module<any, any> = {
    state,
    mutations,
    actions,
    getters,
}

export default groupModule