import { createStore } from 'vuex'
import header from "./header";
import login from './login'
import testApi from './testApi'
import auth from './auth'
import user from './user'
import admin from './admin'
import company from './company'
import role from './role'
import permission from './permission'
import personalAccessKey from './personalAccessKey'
import exam from './exam'
import upload from './upload'
import temporaryExam from './temporary_exam'
import category from './category'
import comment from './comment'
import takeExam from './take_exam'
import userTakeExamStatistics from './userTakeExamStatistics'
import examTakeExamStatistics from './examTakeExamStatistics'
import questionTakeExamStatistics from './questionTakeExamStatistics'
import road from './road'
import roadSent from './roadSent'
import loading from './loading'
import searchQuery from './search_query'
import keyword from './keyword'
import label from './label'
import categoryNewses from './categoryNewses'
import menuFooter from './menuFooter'
import mediaPress from './mediaPress'
import adviser from './adviser'
import newses from './newses'
import ads from './ads'
import contentAds from './contentAds'
import tabAds from './tabAds'
import displayAds from './displayAds'
import scoreSheet from './scoreSheet'
import group from './group'
import order from './order'

export default createStore({
  modules: {
    testApi,
    header,
    login,
    auth,
    user,
    admin,
    company,
    role,
    permission,
    personalAccessKey,
    exam,
    upload,
    temporaryExam,
    category,
    comment,
    takeExam,
    userTakeExamStatistics,
    examTakeExamStatistics,
    questionTakeExamStatistics,
    road,
    roadSent,
    loading,
    searchQuery,
    keyword,
    label,
    categoryNewses,
    newses,
    adviser,
    menuFooter,
    mediaPress,
    ads,
    contentAds,
    tabAds,
    displayAds,
    scoreSheet,
    group,
    order
  },
  state: {
    currentPageName: '',
  },
  mutations: {
    setCurrentPageName(state, name) {
      state.currentPageName = name;
    },
  },
  actions: {
    updateCurrentPageName({ commit }, name) {
      commit('setCurrentPageName', name);
    },
  },
  getters: {
    getCurrentPageName: (state) => state.currentPageName,
  },
})

